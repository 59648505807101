import React from 'react';
import { Link } from 'gatsby';
import { Section } from '../../types/PropTypes';
import Container from './style';
import logo from '../../assets/logo.png';

type NavListProps = {
  section: Section;
};

type HeaderProps = {
  sections?: Section[];
};

const NavList: React.FC<NavListProps> = ({ section }) => (
  <li className="nav-item" id={`nav-${section.id}`}>
    <Link to={`#${section.id}`} className="section-link">
      {section.label}
    </Link>
  </li>
);

const Header: React.FC<HeaderProps> = ({ sections }) => {
  return (
    <Container>
      <nav className="navbar navbar-default navbar-fixed-top" role="navigation">
        <div className="container">
          <div className="navbar-header">
            <Link to="/" className="navbar-brand">
              <img src={logo} alt="FlagSystems" />
            </Link>
          </div>
          {sections && (
            <div className="collapse navbar-collapse" id="navbar-collapse">
              <ul className="nav navbar-nav navbar-right">
                {sections.map(section => (
                  <NavList section={section} key={section.id} />
                ))}
              </ul>
            </div>
          )}
        </div>
      </nav>
    </Container>
  );
};

export default Header;
