import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Container from './style';

const Footer: React.FC = () => {
  return (
    <Container>
      <ul className="flat">
        <li className="first">
          <Link to="/">
            <FontAwesomeIcon icon="home" />
            <span className="link-label">Home</span>
          </Link>
        </li>
        <li>
          <Link to="/privacy/">
            <FontAwesomeIcon icon="lock" />
            <span className="link-label">個人情報保護方針</span>
          </Link>
        </li>
        <li>
          <a href="mailto:contact@flag-systems.co.jp">
            <FontAwesomeIcon icon="envelope" />
            <span className="link-label">お問い合わせ</span>
          </a>
        </li>
      </ul>
      <address>&copy;&nbsp;2009 FlagSystems Inc.</address>
    </Container>
  );
};

export default Footer;
