import styled from 'styled-components';
import Colors from '../consts/Colors';
import Middleline from './Middleline';

export default styled.section`
  font-size: 12px;
  line-height: 1.4em;
  padding: 0;
  h1 {
    font-size: 26px;
    color: ${Colors.textHeader};
    margin: 0;
    padding: 0 0 0 60px; // 左に余白を用意
    height: 40px;
    ${Middleline(40)};
  }
  h2 {
    font-size: 16px;
    font-weight: bold;
    color: ${Colors.textContent};
  }
  h3 {
    font-size: 13px;
    font-weight: bold;
    color: ${Colors.textContent};
  }
  .section-content {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 8px;
    margin-right: 8px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: #ffffff;
    border-radius: 8px;
  }
`;
