export default {
  top: 'rgb(138, 180, 202)',
  service: 'rgb(149, 93, 165)',
  business: 'rgb(159, 160, 160)',
  message: 'rgb(237, 175, 51)',
  company: 'rgb(209, 213, 35)',
  privacy: 'rgb(149, 93, 165)',
  topTopicsHeader: 'rgb(235, 235, 216)',
  topTopicsBorderTop: 'rgb(210, 215, 32)',
  topTopicsBorderBottom: 'rgb(146, 179, 31)',
  textHeader: 'rgb(130, 130, 130)',
  textContent: 'rgb(88, 88, 88)',
};
