import styled from 'styled-components';
import backgroundFooter from '../../assets/background_footer.png';

export default styled.div`
  height: 80px;
  background-image: url(${backgroundFooter});
  padding: 0;
  border-top: 1px solid #aaa;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  ul.flat {
    margin-top: 20px;
    padding: 0;
    text-align: center;
  }
  li.first {
    border-left: none;
  }
  li {
    display: inline;
    border-left: 1px solid #000;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  a {
    color: #666666;
  }
  .link-label {
    margin-left: 5px;
  }
  address {
    font-size: 12px;
    position: relative;
    text-align: center;
    color: #666666;
  }
`;
