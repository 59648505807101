import styled from 'styled-components';
import Colors from '../../consts/Colors';

export default styled.div`
  border-bottom: 1px solid #e5e5e5;
  background-color: #ffffff;
  .navbar-brand {
    padding-left: 30px;
  }
  /* nav */
  #nav-top:hover {
    background-color: #ffffff;
    border-bottom: 5px solid ${Colors.top};
  }
  #nav-service:hover {
    background-color: #ffffff;
    border-bottom: 5px solid ${Colors.service};
  }
  #nav-business:hover {
    background-color: #ffffff;
    border-bottom: 5px solid ${Colors.business};
  }
  #nav-message:hover {
    background-color: #ffffff;
    border-bottom: 5px solid ${Colors.message};
  }
  #nav-company:hover {
    background-color: #ffffff;
    border-bottom: 5px solid ${Colors.company};
  }
  #nav-privacy:hover {
    background-color: #ffffff;
    border-bottom: 5px solid ${Colors.privacy};
  }

  /* Responsive: Portrait tablets and up */
  @media screen and (min-width: 768px) {
    /* Space out the masthead */
    margin-bottom: 30px;
    background-color: #ffffff;
    .navbar-brand {
      padding-left: 15px; // bootstrapのデフォルト値と同じ
    }
  }
`;
