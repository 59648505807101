import { css } from 'styled-components';

// 文字の縦位置を中央にする
// 参考）http://liginc.co.jp/web/html-css/css/64459
export default (height: number) => {
  return css`
    height: ${height}px;
    line-height: ${height}px;
    &:not(:target) {
      line-height: ${height}px;
    }
    :root & {
      line-height: ${height}px;
    }
  `;
};
