import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.css';
import { Section } from '../../types/PropTypes';
import Header from '../Header';
import Footer from '../Footer';
import Container, { GlobalStyle } from './style';
import opgImage from '../../assets/opg.png';

// register font-awesome to use
library.add(faHome, faLock, faEnvelope);

type Props = {
  sections?: Section[];
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ sections, children }) => {
  useEffect(() => {
    /*
     * window オブジェクトを利用するライブラリを import すると Gatsby の build 時にエラーが発生してしまう。
     * この対策として、if文の中でライブラリを読み込む。
     * https://www.gatsbyjs.org/docs/debugging-html-builds/
     */
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line global-require
      const WOW = require('wowjs');
      const wow = new WOW.WOW({
        live: false,
      });
      wow.init();
    }
  }, []);
  return (
    <Container>
      <GlobalStyle />
      <Helmet
        defaultTitle="株式会社フラッグシステムズ"
        titleTemplate="%s | 株式会社フラッグシステムズ"
      >
        <meta
          name="keywords"
          content="フラッグシステムズ,FlagSystems,Flag Systems,ソフトウェア開発,情報システム,WEBサービス"
        />
        <meta
          name="description"
          content="東京都新宿区にあるシステム開発会社、株式会社フラッグシステムズの公式企業サイトです。"
        />
        <meta property="og:title" content="株式会社フラッグシステムズ" />
        <meta property="og:type" content="article" />
        <meta
          property="og:description"
          content="東京都新宿区にあるシステム開発会社、株式会社フラッグシステムズの公式企業サイトです。"
        />
        <meta property="og:url" content="http://www.flag-systems.co.jp/" />
        <meta property="og:image" content={opgImage} />
        <meta property="og:site_name" content="株式会社フラッグシステムズ" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="東京都新宿区にあるシステム開発会社、株式会社フラッグシステムズの公式企業サイトです。"
        />
        <html lang="ja" />
      </Helmet>
      <Header sections={sections} />
      {children}
      <Footer />
    </Container>
  );
};

export default Layout;
