import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
`;

export default styled.div`
  /* === default 設定 === */

  /* Everything gets side spacing for mobile first views */
  .header,
  .main,
  .footer {
    padding-left: 0; //15px;
    padding-right: 0; //15px;
  }
  /* Custom page header */
  .header {
    border-bottom: 1px solid #e5e5e5;
    /* Make the masthead heading the same height as the navigation */
    h3 {
      margin-top: 0;
      margin-bottom: 0;
      line-height: 40px;
      padding-bottom: 19px;
    }
  }

  /* === bootstrap の定義の Override === */

  .navbar-default {
    background-color: #fff;
    // border-color: #e7e7e7;
  }
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus {
    // color: #555;
    background-color: #fff;
  }
  .nav > li > a {
    padding-left: 30px;
    padding-right: 30px;
  }
  .container {
    padding-left: 0;
    padding-right: 0;
  }

  /* === 全体設定 === */

  /* 
  * footer を下部に位置する設定
  * 全体ラッパーに flexbox の設定をする
  * 子要素で flex: 1; を設定する
  */
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  /* 画像サイズを表示領域に合わせる */
  .resizable {
    width: 100%;
  }
  /*
  * マウスオーバーでシルエット画像切り替え
  * hoverと画像URLは個別設定の方に実装している
  */
  .silhouette-image {
    display: block;
    margin-left: auto; // センタリング
    margin-right: auto; // センタリング
    width: 100px;
    height: 100px;
  }

  /* Responsive: Portrait tablets and up */
  @media screen and (min-width: 768px) {
    /* === default 設定 === */

    .container {
      max-width: 900px;
    }
    /* Remove the padding we set earlier */
    .header,
    .main,
    .footer {
      padding-left: 0;
      padding-right: 0;
    }
    /* Space out the masthead */
    .header {
      margin-bottom: 30px;
    }

    /* === 個別設定  === */

    /* header */
    .header {
      background-color: #ffffff;
      .navbar-brand {
        padding-left: 15px; // bootstrapのデフォルト値と同じ
      }
    }
    /* section */
    .section-content {
      margin-left: 20px; // 横幅をちょうど100px狭める
      margin-right: 20px; // 横幅をちょうど100px狭める
      padding-left: 30px; // 横幅をちょうど100px狭める
      padding-right: 30px; // 横幅をちょうど100px狭める
    }
  }
`;
